// import Vue from 'vue';
import WebEditor from './WebEditor.vue';

const Components = {
  WebEditor
}

// Object.keys(Components).forEach((name) => {
//   Vue.component(name, Components[name])
// })

export { WebEditor }
export default Components
