<template>
  <div class="unlayer-frame">
    <div
      :id="id"
      class="unlayer-editor"
      :style="{ minHeight: minHeight }"
    ></div>
    <MediaStore ref="MediaStore" :Config="MediaStoreConfig"></MediaStore>
  </div>
</template>

<script>
import { loadScript } from './loadScript'
let lastEditorId = 0
export default {
  name: 'EmailEditor',
  components: {
    MediaStore: () => import('@/components/public/Media')
  },
  props: {
    editorId: String,
    options: Object,
    projectId: Number,
    tools: Object,
    appearance: Object,
    locale: String,
    minHeight: {
      type: String,
      default: '500px',
    },
  },
  data () {
    return {
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      }
    }
  },
  computed: {
    id() {
      return this.editorId || `editor-${++lastEditorId}`;
    },
  },
  mounted() {
    loadScript(this.loadEditor.bind(this));
  },
  methods: {
    loadEditor() {
      const options = this.options || {};
      if (this.projectId) {
        options.projectId = this.projectId;
      }
      if (this.tools) {
        options.tools = this.tools;
      }
      if (this.appearance) {
        options.appearance = this.appearance;
      }
      if (this.locale) {
        options.locale = this.locale;
      }
      /* global unlayer */
      this.editor = unlayer.createEditor({
        ...options,
        id: this.id,
        displayMode: options.displayMode || 'email',
        source: {
          name: 'vue-email-editor',
          version: '0.9.0',
        },
        amp: true,
        translations: {
          'zh-TW': {
            'editor.placeholder.text': '目前沒有內容，您可以從右側將組件拖曳至此以新增內容。',
            'tools.tabs.body': '樣式',
            'content_tools.carousel': '輪播'
          }
        }
      })
      this.$emit('load');
      this.editor.addEventListener('editor:ready', () => {
        this.$emit('ready')
      })
      this.RegisterCustomMedia()
    },
    loadDesign(design) {
      this.editor.loadDesign(design);
    },
    saveDesign(callback) {
      this.editor.saveDesign(callback);
    },
    exportHtml(callback) {
      this.editor.exportHtml(callback);
    },
    RegisterCustomMedia () {
      const VueInstance = this
      this.editor.registerCallback('selectImage', (data, done) => {
        VueInstance.OpenMediaStore()
        VueInstance.$refs.MediaStore.$on('SelectImage', (path) => {
          if (this.$store.state.user.permission.StaticDomain) {
            done({ url: this.$store.state.user.permission.StaticDomain + path })
          } else {
            this.$store.state.userProjectApp.storage().ref().child(path).getDownloadURL().then((URL) => {
              done({ url: URL })
            })
          }
        })
      })
    },
    OpenMediaStore() {
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = false
    }
  }
}
</script>

<style scoped>
.unlayer-editor {
  flex: 1;
  display: flex;
}
</style>
