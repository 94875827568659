<template>
  <div id="EditorInstance" ref="EditorInstance" class="c-app">
    <CHeader>
      <CHeaderNav class="d-md-down-none mr-auto">
        <CHeaderNavItem class="px-3">
          <CHeaderNavLink to="/">
            <i class="fas fa-angle-left mr-2"/> 回到管理後台
          </CHeaderNavLink>
        </CHeaderNavItem>
      </CHeaderNav>
      <CHeaderNav class="d-md-down-none ml-auto">
        <CHeaderNavItem class="px-1 pr-3">
          <CButton class="mr-2" color="info" @click="BasicModal = true">
            基礎設定
          </CButton>
          <CButton class="mr-2" color="success" @click="Save()">
            {{ $t('Global.Save') }}
          </CButton>
          <CButton class="d-none mr-2" color="success" @click="saveDesign()">
            Save Design
          </CButton>
          <CButton class="d-none mr-2" color="success" @click="exportHtml()">
            Export HTML
          </CButton>
        </CHeaderNavItem>
      </CHeaderNav>
    </CHeader>
    <CWrapper>
      <div id="LayerEditor">
        <WebEditor ref="webEditor" locale="zh-TW" :options="Options" v-on:load="editorLoaded" />
      </div>
    </CWrapper>
    <CModal id="BasicModal" class="ActionModel" :show.sync="BasicModal" size="lg" :centered="true" :closeOnBackdrop="!Submit">
      <CTabs fade addTabsWrapperClasses="tab-info">
        <CTab :title="$t('Post/Detail.Info')" active>
          <CRow>
            <CCol md="8">
              <CInput
                :label="$t('Post/Detail.Slug')"
                v-model="Data.Slug"
                :disabled="FixSlug.includes($route.params.slug)"
                horizontal
              />
              <CInput
                :label="$t('Post/Detail.Name')"
                v-model="Data.Name"
                horizontal
              />
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('Post/Detail.SEO')">
          <CRow>
            <CCol md="8">
              <CInput
                :label="$t('Post/Detail.SEO/Title')"
                v-model="Data.SEO.Title"
                horizontal
              />
              <CInput
                :label="$t('Post/Detail.SEO/Keyword')"
                v-model="Data.SEO.Keywords"
                horizontal
              />
              <CInput
                :label="$t('Post/Detail.SEO/Description')"
                v-model="Data.SEO.Description"
                horizontal
              />
              <CInput
                :label="$t('Post/Detail.SEO/OG:Title')"
                v-model="Data.SEO.OGTitle"
                horizontal
              />
              <CInput
                :label="$t('Post/Detail.SEO/OG:Description')"
                v-model="Data.SEO.OGDescription"
                horizontal
              />
              <CInput
                :label="$t('Post/Detail.SEO/OG:Image')"
                v-model="Data.SEO.OGImage"
                horizontal
              />
            </CCol>
          </CRow>
        </CTab>
      </CTabs>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.BasicSetting') }}</h5>
          <CButtonClose @click="BasicModal = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Loading === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="BasicModal = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="Save()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </div>
</template>

<route>
{
"meta": {
"label": "頁面內容"
}
}
</route>

<script>
import { WebEditor } from '@/plugins/unlayer'

export default {
  name: 'CustomPageDetail',
  components: {
    WebEditor
  },
  data() {
    return {
      Options: {
        displayMode: 'web'
      },
      Submit: false,
      NowChooseLanguage: 'default',
      Action: 'Edit',
      Loading: false,
      Toggle: false,
      ShowSlug: false,
      BasicModal: false,
      Data: {
        Slug: '',
        Taxonomy: 'Page',
        Variable: {},
        SEO: {}
      },
      FixSlug: ['home', 'about', 'private_policy', 'terms', 'member_rules']
    }
  },
  computed: {
    CurrentLocales () {
      return this.$store.state.user.permission.Locales.filter(item => item !== this.$store.state.user.permission.DefaultLocale)
    }
  },
  created() {
    let DataSchema
    this.Action = (this.$route.name === 'custom-page-detail-add' ? 'Add' : 'Edit')
    try {
      DataSchema = require(`@/schema/themesBuild/${this.$route.params.slug}`)
    } catch (error) {
      this.ShowSlug = true
      DataSchema = require(`@/schema/themesBuild/default`)
    }
    this.$set(this.$data, 'Data', Object.assign(this.$data.Data, DataSchema))
    const PermissionSession = JSON.parse(localStorage.getItem('Permission')) || {}
    document.title = this.Data.Name + ' - ' + (PermissionSession.ProjectTitle ? PermissionSession.ProjectTitle : process.env.VUE_APP_PLATFORM_TITLE)
    if (this.$store.state.user.permission.MutipleLocale) {
      this.NowChooseLanguage = this.$route.params.slug.split('_')[1] || 'default'
    }
  },
  mounted() {
    const Execute = []
    this.$Progress.start()
    // if (this.Action === 'Edit') {
    //   Execute.push(
    //     this.Load()
    //   )
    // }
    return Promise.all(Execute).then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  methods: {
    Load () {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/content/article/detail',
          Data: {
            Taxonomy: 'Page',
            Slug: this.$route.params.slug,
            Manage: true
          }
        }
      }).then(({data}) => {
        this.Loading = false
        // 有資料則改為編輯動作，沒資料則為新增動作
        if (data.Data !== null) {
          this.Action = 'Edit'
          this.$refs.webEditor.editor.loadDesign(data.Data.Variable.Design)
          this.Data = data.Data
        } else {
          this.Data.Slug = this.$route.params.slug || ''
          this.Action = 'Add'
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Save () {
      const SendData = {}
      this.Loading = true
      this.$refs.webEditor.editor.exportHtml((data) => {
        if (this.Action === 'Edit') {
          SendData.URL = '/content/article/edit'
          SendData.Data = {
            Taxonomy: 'Page',
            Slug: this.Data.Slug,
            UpdateData: {
              Slug: this.Data.Slug,
              SEO: this.Data.SEO,
              // 'Variable.HTML': data.html,
              'Variable.Design': data.design,
              'Variable.Body': data.chunks.body,
              'Variable.CSS': data.chunks.css.replace(new RegExp('body', 'g'), 'u-body').replace(new RegExp('html', 'g'), 'u-html'),
              'Variable.Font': data.chunks.fonts,
              'Variable.Tags': data.chunks.tags,
            }
          }
        } else {
          this.Data.Variable.Design = data.design
          this.Data.Variable.Body = data.chunks.body
          this.Data.Variable.CSS = data.chunks.css.replace(new RegExp('body', 'g'), 'u-body').replace(new RegExp('html', 'g'), 'u-html'),
          this.Data.Variable.Font = data.chunks.fonts
          this.Data.Variable.Tags = data.chunks.tags
          SendData.URL = '/content/article/add'
          SendData.Data = this.Data
        }
        return this.$store.dispatch('InnerRequest', {
          url: '/service/action',
          method: 'post',
          data: SendData
        }).then(() => {
          this.Loading = false
          this.BasicModal = false
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Success'),
            text: this.$t('Message.Global/SaveSuccess'),
            type: 'success'
          })
          if (this.Action === 'Add') {
            this.$router.replace('/content/page/detail/' + this.Data.Slug)
          }
        }).catch((err) => {
          this.Loading = false
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Success'),
            text: this.$t('Message.Global/SaveFail') + err,
            type: 'error'
          })
        })
      })
    },
    editorLoaded() {
      const Execute = []
      this.$Progress.start()
      if (this.Action === 'Edit') {
        Execute.push(
          this.Load()
        )
      }
      return Promise.all(Execute).then(() => {
        this.$Progress.finish()
      }).catch((err) => {
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: 'error'
        })
      })
    },
    saveDesign() {
      this.$refs.webEditor.editor.saveDesign(
        (design) => {
          console.log('saveDesign', design);
        }
      )
    },
    exportHtml() {
      this.$refs.webEditor.editor.exportHtml(
        (data) => {
          console.log('exportHtml', data);
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
#LayerEditor {
  margin-top: 57px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  height: 100%;
  .unlayer-frame,.unlayer-editor {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
</style>
