module.exports = {
  Name: '使用條款',
  Slug: '',
  OtherName: '',
  Introduction: '',
  HeaderText: '',
  Content: '',
  Attachment: '',
  Categories: [],
  Tags: [],
  Status: 1,
  Sort: 0,
  EnableResponseContent: 0,
  SEO: {
    Description: '',
    Keywords: '',
    Title: '',
    OGTitle: '',
    OGDescription: '',
    OGImage: ''
  },
  Variable: {
    _v: 0
  },
  Cover: '',
  Carousel: [],
  Gallery: [],
  OverViewImages: [],
  LanguageData: {},
  ResponseData: {
    Mobile: {
      Content: ''
    },
    Web: {
      Content: ''
    }
  }
}
